<template>
  <div class="wrongBookClasses">
    <div class="bookInfo flex-row flex-al-center">
      <div class="bookCover">
        <img :src="query.cover" />
      </div>
      <div class="bookNameInfo flex-col">
        <div class="bookName">{{ query.bookName }}</div>
        <div class="bookTj flex-row flex-al-center">
          <div class="textBorder">
            批阅
            <div class="border position_row_center"></div>
          </div>
          <div class="num">{{ book.markQuestionCt || "0" }}道</div>
          <div class="textBorder type2">
            错题
            <div class="border position_row_center"></div>
          </div>
          <div class="num">{{ book.wrongCt || "0" }}道</div>
        </div>
      </div>
    </div>
    <div class="questionList flex-col flex-al-center">
      <div class="selectView flex-row flex-jc-bt flex-al-center">
        <div
          class="selectSlot flex-center"
          style="margin-right: 7px"
          @click="calendarShow = true"
        >
          {{ dateShow }}
          <img
            class="position_col_center"
            src="https://img01.yzcdn.cn/upload_files/2022/04/01/Fvb2z-bUcTUE1gawTdOSUQpzpmg1.png"
          />
        </div>
        <van-calendar
          v-model:show="calendarShow"
          :default-date="range"
          :min-date="minDate"
          :max-date="new Date()"
          allow-same-day
          type="range"
          @confirm="change"
        />
        <VantPopPicker
          @confirm="bindPickerChange"
          :columns="classes"
          :disabled="classes.length === 0"
        >
          <div class="selectSlot flex-center">
            {{ classShow }}
            <img
              class="position_col_center"
              src="https://img01.yzcdn.cn/upload_files/2022/04/01/Fvb2z-bUcTUE1gawTdOSUQpzpmg1.png"
            />
          </div>
        </VantPopPicker>
      </div>
      <div
        v-if="!loading && questions.length < 1"
        style="
          margin: auto;
          color: #c5c5c5;
          font-size: 13px;
          text-align: center;
        "
      >
        暂无数据
      </div>
      <div class="questions">
        <div
          class="questionItem"
          v-for="(item, index) in questions"
          :key="item.questionId"
        >
          <div class="questionTitle flex-row flex-jc-bt flex-al-center">
            <div class="questionName flex-row flex-al-center">
              <div class="tag">
                <img
                  src="https://img01.yzcdn.cn/upload_files/2022/03/25/Fm0RvQkNd-1lyWD6Bn8cjY3liJgK.png"
                />
              </div>
              <span class="questionIndex">{{ index + 1 }}</span>
              <span class="questionRealName"
                >p{{ item.question.pageNo }} - {{ item.question.name }}</span
              >
            </div>
            <div class="questionTj flex-row flex-al-center">
              <div class="questionTjUnit">
                <span>批阅：{{ item.markQuestionCt }}人</span>
              </div>
              <div class="questionTjUnit">
                <span>出错：{{ item.wrongCt }}人</span>
              </div>
            </div>
          </div>
          <div class="questionContent" v-if="item.question">
            <div class="cutImg">
              <img
                v-for="img in getCutImages(item.question.attaches)"
                :key="img"
                :src="img"
                mode="widthFix"
              />
            </div>
            <div class="wrongPeople flex-row">
              <span class="label">出错名单：</span>
              <span>{{ item.studentNumberList.join("、") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  listWrongData4Book,
  listWrongData4Question,
} from "../../API/statistical";
import {
  initZidian,
  getJoinName,
} from "@/project/smartHomeworkUserClient/common/bookName";
import { ossUrl } from "@/static/js/oss";
import { formatDate, groupBy } from "@/static/js/zjs";
import { phaseDic } from "../../common/dictionary";
import { Options, Vue } from "vue-class-component";
import VantPopPicker from "@/components/VantPopPicker";

@Options({
  components: { VantPopPicker },
  data() {
    const now = new Date();
    const defaultRange = [now, now];
    const minDate = new Date(
      now.getFullYear(),
      now.getMonth() - 12,
      now.getDate()
    );
    return {
      loading: true,
      phaseDic,
      defaultRange,
      query: {},
      calendarShow: false,
      minDate,
      range: [...defaultRange],
      book: {},
      classesQuestion: [],
      classes: [],
      classValues: [],
      index: 0,
    };
  },
  computed: {
    dateShow() {
      const dates = this.range.map((i) => formatDate("yyyy.MM.dd", i));
      return dates.length > 0 ? dates.join(" - ") : "日期选择";
    },
    classShow() {
      return this.classes[this.index] || "班级选择";
    },
    questions() {
      return (
        this.classesQuestion.find((j) => {
          const i = j[0];
          return (
            `${i.enterSchoolYear}-${i.phase}-${i.classNumber}` ===
            this.classValues[this.index]
          );
        }) || []
      );
    },
  },
  async mounted() {
    this.loading = true;
    this.query = this.$route.query;
    await initZidian();
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      // wx.showLoading({
      //   title: "数据加载中",
      // });
      await listWrongData4Book({
        ...this.query,
        startTime: this.range[0],
        endTime: this.range[1],
      }).then((res) => {
        if (res.status === 200 && res.data.length > 0) {
          const bookList = res.data.map((i) => {
            const { joinName } = getJoinName(i.book);
            i.bookName = joinName;
            i.cover = ossUrl(i.book.thumbCoverPath);
            i.showTime = formatDate(
              "yyyy年MM月dd日 HH:mm:ss",
              i.latestUpdateTime
            );
            return i;
          });
          this.book = bookList[0];
        }
      });
      await listWrongData4Question({
        ...this.query,
        startTime: this.range[0],
        endTime: this.range[1],
      }).then((res) => {
        if (res.status === 200) {
          const arr = groupBy(
            res.data,
            (i) => `${i.enterSchoolYear}-${i.phase}-${i.classNumber}`
          );
          this.classesQuestion = arr;
          this.classes = arr.map((i) => {
            const data = i[0];
            return `${this.phaseDic[data.phase]} ${data.enterSchoolYear}级${
              data.classNumber
            }班`;
          });
          this.classValues = arr.map((i) => {
            const data = i[0];
            return `${data.enterSchoolYear}-${data.phase}-${data.classNumber}`;
          });
          // wx.hideLoading();
          this.loading = false;
        }
      });
    },
    change(val) {
      this.range = val;
      if (val.length < 1) {
        this.$nextTick(() => {
          this.range = this.defaultRange;
        });
      } else {
        this.getData();
      }
      this.calendarShow = false;
      console.log(this.range);
    },
    bindPickerChange(e) {
      this.index = e.detail.value;
    },
    getCutImages(attaches = []) {
      return attaches.map((attache) => {
        return (
          `${ossUrl(attache.page.picPath)}?x-oss-process=image/crop,` +
          `x_${parseInt(attache.ltx)},` +
          `y_${parseInt(attache.lty)},` +
          `w_${parseInt(attache.width)},` +
          `h_${parseInt(attache.height)}`
        );
      });
    },
  },
})
export default class wrongBookClasses extends Vue {}
</script>

<style lang="scss">
.wrongBookClasses {
  padding-bottom: calc(12px + var(--safe-area-inset-bottom));
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow: auto;
  background: linear-gradient(180deg, #edf7ff 0%, rgba(245, 249, 252, 0) 100%);

  .bookInfo {
    margin-top: 8px;
    width: 359px;
    height: 103px;
    flex-shrink: 0;
    background: #ffffff;
    border-radius: 12px;

    .bookCover {
      margin-left: 11px;
      flex-shrink: 0;
      width: 59px;
      height: 82px;
      border: 1px solid #e4e4e4;
      border-radius: 5px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .bookNameInfo {
      margin-left: 15px;
      height: 100%;

      .bookName {
        margin-top: 18px;
        font-size: 14px;
        color: #202020;
      }

      .bookTj {
        margin-top: 20px;

        .textBorder {
          position: relative;
          z-index: 1;
          font-size: 12px;
          color: #666666;

          .border {
            bottom: -2px;
            z-index: -1;
            width: 28px;
            height: 6px;
            background: #e6efff;
          }

          &.type2 {
            margin-left: 38px;
          }
        }

        .num {
          margin-left: 7px;
          font-size: 12px;
          color: #666666;
        }
      }
    }
  }

  .questionList {
    width: 359px;
    flex-grow: 1;
    margin-top: 8px;
    border-radius: 12px;
    background: #ffffff;

    .selectView {
      padding: 10px 11px;

      .selectSlot {
        position: relative;
        width: 165px;
        height: 34px;
        font-size: 11px;
        color: #333333;
        border: 1px solid #cacaca;
        box-sizing: border-box;
        border-radius: 6px;
        padding-right: 20px;

        img {
          right: 10px;
          width: 13px;
          height: 8px;
        }
      }
    }

    .questions {
      width: 100%;
      padding: 0 11px;

      .questionItem {
        margin-top: 26px;

        &:first-child {
          margin-top: 16px;
        }

        .questionTitle {
          .questionName {
            .tag {
              img {
                width: 20px;
                height: 10px;
              }
            }

            .questionIndex {
              margin-left: 3px;
              font-size: 14px;
              color: #3f90ff;
            }

            .questionRealName {
              margin-left: 11px;
              font-size: 12px;
              color: #202020;
            }
          }
        }

        .questionTj {
          .questionTjUnit {
            margin-right: 16px;
            font-size: 12px;
            color: #666666;

            &:last-child {
              margin-right: 5px;
            }
          }
        }

        .questionContent {
          margin-top: 6px;
          border: 1px solid #d0e3ff;
          border-radius: 5px;
          overflow: hidden;

          .cutImg {
            width: 100%;

            img {
              width: 100%;
              height: auto;
            }
          }

          .wrongPeople {
            .label {
              white-space: nowrap;
              margin-top: 1px;
            }

            padding: 10px;
            font-size: 12px;
            line-height: 20px;
            color: #3f90ff;
            background: #f2f7ff;
          }
        }
      }
    }
  }
}
</style>
